<template>
  <el-dialog title="短信通知" :visible.sync="dialogShow" width="600px" :before-close="handleClose">
    <el-form size="small" label-width='100px'>
      <OrganizationSelect v-model="orgId" @input="formChange" :showAll="false" :isCheck="false" style="width:520px"></OrganizationSelect>
      <el-form-item label="通知类型">
        <el-radio v-model="isReceive" label="0" @change="formChange">未领取</el-radio>
        <el-radio v-model="isReceive" label="1" @change="formChange">领取未使用</el-radio>
        <el-select v-model="amount" placeholder="请选择" style="width:150px" @change="formChange">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <div v-show="isReceive!=2" class="data-record"><span> 共有{{objData.listTotal || 0}}人，需发送短信{{objData.listTotal || 0}}条</span><span class="record" @click="exportData">数据导出</span></div>
    </el-form>
    <div style="margin-top:100px">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" v-debounce="submit" :loading="loading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import OrganizationSelect from '@/components/OrganizationSelect.vue'
import { selectIntegralByIsReceive, getIntegralSms } from "@/api/integrals";
import { getCookies, exportCommon } from "@/utils/utils";
export default {
  name: "SendMessage",
  props: ["dialogShow"],
  components: { OrganizationSelect },
  data() {
    return {
      loading: false,
      isReceive: "2",
      amount: "0",
      options: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "10",
          label: "大于10"
        },
        {
          value: "20",
          label: "大于20"
        },
        {
          value: "50",
          label: "大于50"
        },
        {
          value: "100",
          label: "大于100"
        },
        {
          value: "500",
          label: "大于500"
        },
        {
          value: "1000",
          label: "大于1000"
        },
        {
          value: "5000",
          label: "大于5000"
        },
        {
          value: "10000",
          label: "大于10000"
        }
      ],
      objData: {},
      orgId: ''
    };
  },
  created() {
    this.orgId = getCookies('organizationId')
  },
  methods: {
    exportData() {
      let param = {
        orgId: this.orgId,
        isReceive: this.isReceive,
        amount: this.amount
      };
      exportCommon("/wlynIntegral/exportIntegralInfoExcel", param);
    },
    handleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$emit("update:dialogShow", false);
    },
    formChange() {
      if (this.isReceive == 2) return;
      let param = {
        orgId: this.orgId,
        isReceive: this.isReceive,
        amount: this.amount
      };
      selectIntegralByIsReceive(param).then(res => {
        this.objData = res.data || {};
        console.log(res);
      });
    },
    submit() {
      console.log("dian");
      this.loading = true;
      if (!this.objData.listTotal) {
        this.$message.warning("需发送短信数不能为0");
        this.loading = false;
        return;
      }
      let param = {
        orgId: this.orgId,
        isReceive: this.isReceive,
        amount: this.amount
      };
      getIntegralSms(param)
        .then(res => {
          setTimeout(() => (this.loading = false), 500);
          if (res.code == 200) {
            this.$message.success("提交成功");
            this.handleClose();
          } else {
            this.$message.error("提交失败");
          }
        })
        .catch(err => {
          this.$message.error("提交失败");
          setTimeout(() => (this.loading = false), 500);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.data-record {
  display: flex;
  margin-left: 30px;
  .record {
    margin-left: 50px;
    color: #409eff;
    cursor: pointer;
  }
}
</style>