
<template>
  <div>
    <el-row>
      <el-col :span="22">
        <el-form label-width="95px" inline size="mini">
          <el-form-item label="发放类型">
            <el-select style="width:120px;" v-model="search.batchType" placeholder="选择发放类型" @change="searchClick">
              <el-option v-for="(item,i) in batchTypeList" :key="i" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模糊搜索：">
            <el-input style="width:150px;" placeholder="请输入姓名、手机号" v-model="search.search"></el-input>
          </el-form-item>
          <el-form-item label="核销码：" v-if="search.batchType==4">
            <el-input style="width:150px;" placeholder="请输入核销码" v-model="search.code"></el-input>
          </el-form-item>
          <el-form-item label="领取状态：">
            <el-select style="width:120px;" v-model="search.isUse" placeholder="选择领取状态">
              <el-option label="全部" value></el-option>
              <el-option label="未领取" value="0"></el-option>
              <el-option label="已领取" value="1"></el-option>
              <el-option label="已撤回" value="2"></el-option>
            </el-select>
          </el-form-item>
          <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
          <el-form-item label="创建时间：">
            <el-date-picker style="width:314px;" v-model="createTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="领取时间：">
            <el-date-picker style="width:374px;" v-model="receiveTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="金额：">
            <el-input style="width:200px;" placeholder="请输入金额" v-model="search.amount">
              <el-select style="width:100px;" slot="prepend" v-model="search.amountType" placeholder="请选择">
                <el-option label="大于等于" value="0"></el-option>
                <el-option label="小于等于" value="1"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label=" ">
            <el-button type="primary" icon="el-icon-search" @click="searchClick">查询</el-button>
            <!-- <el-button type="primary" size="mini" icon="el-icon-folder-opened" @click="exportList">导出</el-button> -->
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" size="mini" v-if="search.batchType==6" @click="dialogSendMesg=true" class="right-button">短信通知</el-button>
      </el-col>
    </el-row>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column align="center" label="发放类型">
        <template slot-scope="scope">
          {{exchangeType[scope.row.exchangeType]}}
        </template>
      </el-table-column>
      <el-table-column prop="organizationName" align="center" label="提交机构"></el-table-column>
      <el-table-column prop="title" align="center" label="标题"></el-table-column>
      <el-table-column prop="exchangeCode" align="center" label="兑换码" width="155" v-if="search.batchType==4"></el-table-column>
      <el-table-column prop="customerName" align="center" label="客户" width="130"></el-table-column>
      <el-table-column prop="usedPhone" align="center" label="手机" width="110"></el-table-column>
      <el-table-column prop="exchangeAmount" align="center" label="总金额（元）" width="150"></el-table-column>
      <el-table-column prop="createName" align="center" label="发放人" width="120" v-if="false"></el-table-column>
      <el-table-column prop="createTime" align="center" label="创建时间" width="180"></el-table-column>
      <el-table-column prop="auditStatus" align="center" label="审批状态" width="90">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" disable-transitions :type="auditStatusTag[scope.row.auditStatus].type" v-text="auditStatusTag[scope.row.auditStatus].text"></el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="isReceive" align="center" label="领取状态" width="85">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" :type="scope.row.isReceive == 0 ? 'primary' : scope.row.isReceive == 1 ? 'success' : 'info'" disable-transitions>{{revList[scope.row.isReceive]}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="receiveTime" align="center" label="领取时间" width="180"></el-table-column>
      <el-table-column prop="createPhone" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoInfo(scope.row)">查看</el-button>
          <el-button icon="el-icon-refresh-left" type="text" size="mini" class="btn-hover" @click="recall(scope.row.infoId,scope.$index)" :disabled="!scope.row.isReceive == 0">撤回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display:flex;width:100%">
      <p style="line-height:30px;width:200px">总金额 : {{totalCount || 0}}</p>
      <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total" style="text-align:center;flex:1"></el-pagination>
    </div>
    <SendMessage :dialogShow.sync="dialogSendMesg" v-if="dialogSendMesg"></SendMessage>
    <info :show.sync="dialogInfo" :info="info" v-if="dialogInfo"></info>
  </div>
</template>
<script>
import info from './components/info.vue'
import { displayByCustomer, removeBack, getIntegralGrantTotalAmount } from "@/api/integrals";
import { getCookies, auditStatusTag, exportCommon } from "@/utils/utils";
import OrganizationSelect from '@/components/OrganizationSelect.vue'
import SendMessage from "./components/SendMessage.vue";

export default {
  name: "integralGrant",
  components: { SendMessage, OrganizationSelect, info },
  data() {
    return {
      exchangeType: ['核销码', '前三后四', '直接发放', '工号发放'],
      revList: ['未领取', '已领取', '已撤回', '已赠送'],
      auditStatusTag: auditStatusTag,
      batchTypeList: [{ label: '全部', id: '' }, { label: '直接发放', id: '6' }, { label: '核销码', id: '4' }, { label: '前三后四', id: '5' }, { label: '工号发放', id: '11' }],
      search: {
        batchType: '',
        isPage: 1,
        isUse: "",
        search: "",
        submitOrg: '',
        pageNum: 1, //分页页数
        pageSize: 10, //每页显示多少
        code: "",
        amountType: '',
        amount: ''
      },
      code: "",
      receiveTime: "",
      createTime: "",
      list: [],
      organizationList: [],
      total: 0, //分页总页数
      dialogSendMesg: false,
      dialogInfo: false,
      info: {},
      totalCount: 0
    };
  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    // this.getList();
  },
  methods: {
    recall(id, index) {
      let that = this;
      this.$confirm("是否撤回?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(res => {
        removeBack(id).then(response => {
          if (response.code == 200) {
            let item = JSON.parse(JSON.stringify(that.list[index]));
            item["isReceive"] = 2;
            that.$set(that.list, index, item);
          }
          that.$message({
            type: response.code == 200 ? "success" : "default",
            duration: 2000,
            message: response.msg || response.error_msg
          });
        });
      });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList(0);
    },
    handleCurrentChange(val) {
      this.search.pageNum = val;
      this.getList(0);
    },
    getList(t = 1) {
      let param = Object.assign({}, this.search);
      param.receiveStartTime =
        this.receiveTime && this.receiveTime.length ? this.receiveTime[0] : "";
      param.receiveEndTime =
        this.receiveTime && this.receiveTime.length ? this.receiveTime[1] : "";
      param.startTime =
        this.createTime && this.createTime.length ? this.createTime[0] : "";
      param.endTime =
        this.createTime && this.createTime.length ? this.createTime[1] : "";
      if (t == 1) {
        getIntegralGrantTotalAmount(param).then(res => {
          this.totalCount = res.data || 0
        })
      }
      displayByCustomer(param).then(response => {
        if (response.code == 200) {
          this.total = response.data.total;
          this.list = response.data.records;
        }
      });
    },
    searchClick() {
      this.search.pageNum = 1;
      this.getList()
    },
    gotoInfo(item) {
      this.info = item
      this.dialogInfo = true
    },
    exportList() {
      let param = {
        // isUse: this.search.isUse,
        // search: this.search.search,
        // submitOrg: this.search.submitOrg,
        userId: sessionStorage.getItem("wlyn_sysId")
      };
      Object.assign(param, this.search)
      param.submitOrg = param.submitOrg || getCookies('organizationId')
      param.receiveStartTime =
        this.receiveTime && this.receiveTime.length ? this.receiveTime[0] : "";
      param.receiveEndTime =
        this.receiveTime && this.receiveTime.length ? this.receiveTime[1] : "";
      param.startTime =
        this.createTime && this.createTime.length ? this.createTime[0] : "";
      param.endTime =
        this.createTime && this.createTime.length ? this.createTime[1] : "";
      exportCommon("/wlynIntegral/exportIntegralExcel", param);
    }
  }
};
</script>
<style lang="scss" scoped>
.el-tag-style {
  width: 60px;
  text-align: center;
}
</style>