<template>

  <el-dialog title="发放详情" :visible.sync="show" :lock-scroll="false" :close-on-click-modal="false" width="350px" :before-close="dialogClose">
    <el-form label-width="100px">
      <el-form-item label="客户名称">
        {{info.customerName}}
      </el-form-item>
      <el-form-item label="电话号码">
        {{info.usedPhone}}
      </el-form-item>
      <el-form-item label="兑换码" v-if="info.exchangeCode">
        {{info.exchangeCode}}
      </el-form-item>
      <el-form-item label="发放金额">
        {{info.exchangeAmount}}
      </el-form-item>
      <el-form-item label="发放时间">
        {{info.createTime}}
      </el-form-item>
      <el-form-item label="领取状态">
        {{revList[info.isReceive]}}
      </el-form-item>
      <el-form-item label="领取时间" v-show="info.receiveTime">
        {{info.receiveTime}}
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'info',
  props: ['show', 'info'],
  data() {
    return {

      revList: ['未领取', '已领取', '已撤回', '已赠送'],
    }
  },
  methods: {
    dialogClose() {
      this.$emit('update:show', false)
    },
  }
}
</script>

<style>
</style>